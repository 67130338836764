import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  HelfBuddy,
  HelfBuddyResponse,
  SubscriptionModel,
} from '../models/helf-buddy/domain-questions.model';
import { DataService } from './data.service';
//const API_URL = "http://localhost:4000";
const API_URL = 'https://api.helf.co';
const CMSAPI_URL = 'https://cms.vearth.co'; //production environment
// const API_URL = "https://api-dev1.vearth.co";
// const CMSAPI_URL = "http://localhost:4300"; //test environment

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    public _http: HttpClient,
    private dataService: DataService,
    private router: Router
  ) {}

  login: string = '/v1/admin/login';
  user_list: string = '/v1/user/';
  user_list_delete: string = '/v1/user/delete';
  user_report_list: string = '/v1/user/report';
  user_status: string = '/v1/admin/status';
  subscription_types: string = '/v1/subscription';
  set_subscription: string = '/v1/subscription/set';
  buddy_url: string = '/v1/buddy';

  nft_item: string = '/v1/item';
  nft_publish: string = '/v1/item/publish';
  nft_unpublish: string = '/v1/item/unpublish';
  resource_creator: string = '/v1/resource';
  item_category: string = '/v1/item-category';
  item_sub_category: string = '/v1/item-sub-category';
  blockchains: string = '/v1/blockchain';

  monster: string = '/v1/parcel/monster';
  easter_egg: string = '/v1/parcel/easter-egg';
  stationary_npc: string = '/v1/parcel/npc';
  mobile_npc: string = '/v1/parcel/mobile-npc';
  resource_parcel: string = '/v1/parcel/resource';
  parcel_duplicate: string = '/v1/parcel/duplicate';
  parcel_delete: string = '/v1/parcel';
  landmark: string = '/v1/parcel/landmark';

  quest: string = '/v1/quest';
  graveyard: string = '/v1/graveyard';
  tourist_trail: string = '/v1/tourist-trail';
  checkpoint: string = '/v1/tourist-trail/checkpoint';

  mailbox: string = '/v1/mailbox';
  get_mailbox: string = '/v1/mailbox/admin';

  files: string = '/v1/upload';

  business: string = '/v1/business';
  guide: string = '/v1/guide';
  press: string = '/v1/press';
  blog: string = '/v1/blog';
  info: string = '/v1/info';
  cms_upload_file = '/v1/upload';
  cms_get_file: string = CMSAPI_URL + '/v1/file?';

  business_sub_category = '/v1/business-sub-category';

  order: string = '/v1/business/order';

  health_program_category: string = '/v1/health-program-category';
  health_program: string = '/v1/health-program';
  health_plan: string = '/v1/health-plan';

  food_calorie: string = '/v1/food-calorie';

  helf_feebacks: string = '/v1/helf/conversations';
  helf_version: string = '/v1/version';
  helf_client: string = '/v1/client';

  HTTP_POST_METHOD = 'POST';
  HTTP_PATCH_METHOD = 'PATCH';
  HTTP_METHOD = this.HTTP_POST_METHOD;

  public wsSave(
    formData: any,
    method: string,
    token: string = ''
  ): Promise<any> {
    const headers = { Authorization: `Bearer ${token}` };
    if (this.HTTP_METHOD == this.HTTP_POST_METHOD) {
      return this.wsPost(formData, method, token);
    } else {
      return this.wsPatch(formData, method, token);
    }
  }

  public wsPost(
    formData: any,
    method: string,
    token: string = ''
  ): Promise<any> {
    const headers = { Authorization: `Bearer ${token}` };
    return this._http
      .post(API_URL + method, formData, { headers: headers })
      .toPromise();
  }

  public wsPatch(
    formData: any,
    method: string,
    token: string = ''
  ): Promise<any> {
    const headers = { Authorization: `Bearer ${token}` };
    return this._http
      .patch(API_URL + method, formData, { headers: headers })
      .toPromise();
  }

  public wsDuplicate(method: string, token: string = ''): Promise<any> {
    const headers = { Authorization: `Bearer ${token}` };
    return this._http
      .patch(API_URL + method, {}, { headers: headers })
      .toPromise();
  }

  public wsDelete(method: string, token: string = ''): Promise<any> {
    const headers = { Authorization: `Bearer ${token}` };
    return this._http
      .delete(API_URL + method, { headers: headers })
      .toPromise();
  }

  public wsGet(method: string, token: string = ''): Promise<any> {
    const headers = { Authorization: `Bearer ${token}` };
    return this._http.get(API_URL + method, { headers: headers }).toPromise();
  }

  public uploadFile(file: File, token: string): Promise<any> {
    let formData = new FormData();
    formData.append('model', file);

    const headers = { Authorization: token };
    return this._http
      .post(`${API_URL}/v1/upload`, formData, { headers: headers })
      .toPromise();
  }

  public logout() {
    this.dataService.setApiToken('');
    localStorage.removeItem('apitoken');
    localStorage.removeItem('userData');
  }

  public wsCmsPost(formData: any, method: string): Promise<any> {
    const headers = {};
    return this._http
      .post(CMSAPI_URL + method, formData, { headers: headers })
      .toPromise();
  }

  public wsCmsGet(method: string): Promise<any> {
    const headers = {};
    return this._http
      .get(CMSAPI_URL + method, { headers: headers })
      .toPromise();
  }

  public wsCmsPut(formData: any, method: string): Promise<any> {
    const headers = {};
    return this._http
      .put(CMSAPI_URL + method, formData, { headers: headers })
      .toPromise();
  }

  public wsCmsDelete(method: string): Promise<any> {
    const headers = {};
    return this._http
      .delete(CMSAPI_URL + method, { headers: headers })
      .toPromise();
  }

  public uploadCmsFile(method: string, file: File): Promise<any> {
    let formData = new FormData();
    formData.append('filemodel', file);
    const headers = {};
    return this._http
      .post(CMSAPI_URL + method, formData, { headers: headers })
      .toPromise();
  }

  public getAllSub() {
    const token = JSON.parse(localStorage.getItem('apitoken'));

    return this._http.get<{ message: SubscriptionModel[] }>(
      API_URL + this.subscription_types,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  fetchAllBuddy() {
    const token = JSON.parse(localStorage.getItem('apitoken'));

    return this._http.get<{ message: HelfBuddyResponse[]; success: true }>(
      API_URL + this.buddy_url,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  fetchBuddy(id: string) {
    const token = JSON.parse(localStorage.getItem('apitoken'));

    return this._http.get<{ message: HelfBuddyResponse; success: true }>(
      API_URL + this.buddy_url + `/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  createBuddy(body: HelfBuddy) {
    const token = JSON.parse(localStorage.getItem('apitoken'));

    return this._http.post<{ message: { _id: string }; success: boolean }>(
      API_URL + this.buddy_url,
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  updateBuddy(id: string, body: HelfBuddy) {
    const token = JSON.parse(localStorage.getItem('apitoken'));

    return this._http.patch(API_URL + this.buddy_url + `/${id}`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}
